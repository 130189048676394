import React from "react";

export default function CarouselItem({ item }) {
  return (
    <div className="flex items-center justify-center p-4 w-11/12 gap-4">
      <img
        src={item.imgPath}
        alt="product"
        className="object-cover w-1/2 h-3/4"
      />
      <div className="h-3/4 w-1/2 flex flex-col items-center justify-center">
        <h3 className="text-center text-3xl uppercase">{item.productName}</h3>
        <hr className="my-4 w-full" />
        <p className="p-4">{item.productDescription}</p>
      </div>
    </div>
  );
}
