import React from "react";
import thornImg from "../../assets/images/webp/thorn-webp.webp";
// import warsawImg from "../../assets/images/warsaw.jpg";
import brombergImg from "../../assets/images/webp/bromberg-webp.webp";
import { Link } from "react-router-dom";

export default function RestaurantCard({ restaurant }) {
  return (
    <div className="group h-60 md:h-full w-full md:w-1/4 relative cursor-pointer overflow-hidden">
      <Link to={restaurant.appLink}>
        <div className="group h-full w-full relative cursor-pointer overflow-hidden">
          {restaurant.city === "Toruń" ? (
            <img
              src={thornImg}
              alt="Restaurant City"
              className="h-full w-full object-cover object-center group-hover:scale-105 ease-in-out duration-200"
            />
          ) : (
            //: restaurant.city === "Warszawa" ? (
            //   <img
            //     src={warsawImg}
            //     alt="Restaurant City"
            //     className="h-full w-full object-cover object-center group-hover:scale-105 ease-in-out duration-200"
            //   />
            // )
            <img
              src={brombergImg}
              alt="Restaurant City"
              className="h-full w-full object-cover object-center group-hover:scale-105 ease-in-out duration-200"
            />
          )}

          <div className="text-center absolute w-full top-1/2 left-0 -translate-y-1/2 bg-black opacity-80 text-white text-xl py-10 ">
            <h3>{restaurant.city}</h3>
            <hr className="my-2 group-hover:border-red-600" />
            <p>Poznaj lokal</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
