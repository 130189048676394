import React from "react";
import WhitebullVerticalBar from "../WhitebullVerticalBar/WhitebullVerticalBar";
import ButtonRed from "../ButtonRed/ButtonRed";
import deskImage from "../../assets/images/webp/desk-webp.webp";

export default function SpecialEvents({ myFunc }) {
  return (
    <div className="py-10">
      <div className="flex flex-col md:flex-row h-screen md:max-h-96">
        <div className="w-full flex flex-col md:flex-row">
          <div className="text-center flex flex-col gap-8">
            <h3 className="text-3xl py-10">Imprezy okolicznościowe</h3>
            <p className="text-xl px-10">
              dobrze zaplanowane, dostosowane do charakteru wydarzenia oraz
              preferencji uczestników, aby każdy mógł cieszyć się wyjątkowymi
              momentami i tworzyć niezapomniane wspomnienia.
            </p>
            <div className="py-10 animate-pulse">
              <ButtonRed
                text={"Zarezerwuj"}
                outsideFunc={myFunc}
                type={"showEventInfo"}
              />
            </div>
          </div>
          <div className="w-full md:w-8 h-8 md:h-full">
            <WhitebullVerticalBar />
          </div>
        </div>
        <div className="w-full">
          <img
            src={deskImage}
            alt="food-foto"
            className="w-full h-1/3 md:h-full md:max-h-full object-cover "
          />
        </div>
      </div>
    </div>
  );
}
