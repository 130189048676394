import React, { useState } from "react";
import FloatingLogoAndButton from "../FloatingLogoAndButton/FloatingLogoAndButton";
import { account, ID } from "../../lib/appwrite";
import { useNavigate } from "react-router-dom";

function AdminLogin({setUser}) {
  const [form, setForm] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  async function login(email, password) {
    await account.createEmailPasswordSession(email, password);
    //setUser(await account.get());
    setUser = await account.get();
    //navigate("/admin-panel/dashboard");
  }

  const handleChanges = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <FloatingLogoAndButton />
      <div className="bg-black flex justify-center items-center py-4 h-screen">
        <div className="flex flex-col items-center justify-center border-[1px] border-red-600 p-4 gap-4">
          <input
            type="text"
            placeholder="Email"
            name="email"
            className="py-2 px-4 rounded-full bg-transparent text-red-600 border-[1px] border-red-600  hover:border-white"
            onChange={(e) => handleChanges(e)}
          />
          <input
            type="password"
            placeholder="Hasło"
            name="password"
            className="py-2 px-4 rounded-full bg-transparent text-red-600 border-[1px] border-red-600  hover:border-white"
            onChange={(e) => handleChanges(e)}
          />
          <button
            className="bg-red-600 text-white py-2 px-6 rounded-full"
            onClick={() => login(form.email, form.password)}
          >
            Zaloguj
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
