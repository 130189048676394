import React from "react";
import { Outlet } from "react-router-dom";

export default function Header() {
  // <div>
  //         <Link to="/">
  //           <img src={mainLogo} className={"max-w-40"} alt="WhiteBull Logo" />
  //         </Link>
  //       </div>
  //       <ul className="pr-4">
  //         <li>
  //           {/* <Link to={"/"}>{menu[0].name}</Link> */}
  //           <ButtonRed
  //             text={"Zarezerwuj stolik"}
  //             type={"outputLink"}
  //             url={"https://zjedz.my/whitebull-steakhouse"}
  //           />
  //         </li>
  //       </ul>
  return (
    <>
      <header></header>
      <Outlet />
    </>
  );
}
