const adress = [
  {
    id: 1,
    isOpen: true,
    city: "Toruń",
    street: "ul. Mostowa 36",
    tel: "+48505635671",
    openHours1: "13:00 - 22:00",
    openHours2: "13:00 - 01:00",
    openHours3: "13:00 - 22:00",
    imgPath: "",
    appLink: "/torun",
    apartments: [
      {
        apartStreet: "ul. Mostowa 36,",
        apartCity: "Toruń",
        apartPost: "87-100",
        apartTel: "+48505635671",
        apartEmail: "torun-apartamenty@whitebull.com.pl",
        apartBooking: "https://booking.com",
        apartImages: [],
      },
    ],
  },
  // {
  //   id: 2,
  //   city: "Warszawa",
  //   street: "już wkrótce",
  //   tel: "+48505635671",
  //   openHours1: "--:-- - --:--",
  //   openHours2: "--:-- - --:--",
  //   openHours3: "--:-- - --:--",
  //   imgPath: "",
  //   appLink: "/warszawa",
  //   apartments: [],
  // },
  {
    id: 3,
    isOpen: false,
    city: "Bydgoszcz",
    street: "ul. Poznańska 8",
    tel: "+48505635671",
    openHours1: "--:-- - --:--",
    openHours2: "--:-- - --:--",
    openHours3: "--:-- - --:--",
    imgPath: "",
    appLink: "/bydgoszcz",
    apartments: [],
  },
];

export default adress;
