import React from "react";
import { Link, Outlet } from "react-router-dom";
import FooterSocial from "../FooterSocial/FooterSocial";

export default function Footer() {
  return (
    <>
      <Outlet />
      <footer
        className={
          "bg-black text-white flex flex-col-reverse md:flex-row md:justify-between items-center gap-2 md:gap-0 min-h-28 border-t-2 border-white"
        }
      >
        <div className="md:pl-4">
          &copy; 2024 <Link to={"/admin-panel/"}>WHITEBULL</Link>. Wszystkie
          prawa zastrzeżone.
        </div>
        <div className="md:pr-4">
          <FooterSocial />
        </div>
      </footer>
    </>
  );
}
