import React from "react";

export default function ButtonRed({ text, type, url, outsideFunc }) {
  const myClick = (type, url) => {
    switch (type) {
      case "outputLink":
        window.open(url, "_blank", "norefere");
        break;
      case "reservationChoose":
        outsideFunc(true);
        break;
      case "showEventInfo":
        outsideFunc(true);
        break;
      default:
        break;
    }
  };
  return (
    <button
      className="bg-red-700 px-4 md:px-8 py-2 rounded-full  mx-auto text-white"
      onClick={() => myClick(type, url)}
    >
      {text}
    </button>
  );
}
