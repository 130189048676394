import React, { useState } from "react";
import mainLogo from "../../assets/images/whitebull.png";
import { toast } from "react-toastify";
import axios from "axios";

export default function Newsletter() {
  const [formData, setFormData] = useState({ email: "", source: "www" });

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendData = async () => {
    //const api = "http://localhost:5888/api";
    const api = "https://api.whitebull.com.pl/api";
    if (formData.email !== "") {
      const rgExp = /^[a-zA-Z0-9._]+@[a-z]+\.[a-z]{2,6}$/;
      if (rgExp.test(formData.email)) {
        const res = await axios.post(`${api}/newsletter/add`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        toast.success("Dziękujemy za subskrypcje.");
      } else {
        toast.error("Nieprawidłowy adres email");
      }
      //Set formData to init values
      setFormData({ email: "" });
      //Clear input
      const input = document.getElementById("myEmail");
      input.value = "";
    }
    if (formData.email === "") {
      toast.error("Nie wpisano adresu email.");
    }
  };
  return (
    <div className="flex flex-wrap py-10  md:p-10 justify-around items-center gap-2">
      <div>
        <img src={mainLogo} alt="WhiteBull Logo" />
      </div>
      <div className="w-full px-4 md:px-0 md:w-1/3 flex flex-col gap-2">
        <p className="text-lg">Bądź na bieżąco</p>
        <hr />
        <p className="text-2xl">Dołącz do nas</p>
        <div className="pt-2 relative">
          <input
            className="p-4 rounded-full w-full text-black"
            id="myEmail"
            type="email"
            name="email"
            placeholder="E-Mail"
            onChange={(e) => changeHandler(e)}
          />

          <button
            onClick={sendData}
            className="absolute top-4 right-2 z-10 bg-red-600 px-4 py-2 text-white rounded-full"
          >
            Subskrybuj
          </button>
        </div>
      </div>
    </div>
  );
}
