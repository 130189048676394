import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";

export default function FooterSocial() {
  return (
    <div className={"flex justify-center items-center gap-2"}>
      <Link
        to={"https://www.facebook.com/steakhousesubstancja"}
        target="_blank"
        className="group"
      >
        <FaFacebook className={"w-6 h-6 group-hover:text-red-600"} />
      </Link>
      <Link
        to={"https://www.instagram.com/steakhouse.whitebull/"}
        target="_blank"
        className="group"
      >
        <FaInstagram className={"w-7 h-7 group-hover:text-red-600"} />
      </Link>
    </div>
  );
}
