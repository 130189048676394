import React from "react";
import verticalLogo from "../../assets/images/WHITEBULL2.png";
import verticalLogo2 from "../../assets/images/WHITEBULL2hor.png";

export default function WhitebullVerticalBar() {
  return (
    <div className="bg-stone-300 h-8 md:h-full w-full md:w-8 ">
      <img
        src={verticalLogo}
        alt="Whitebull Vertical Logo"
        className="object-center object-contain h-full hidden md:block"
      />
      <img
        src={verticalLogo2}
        alt="Whitebull Vertical Logo"
        className="object-center object-contain h-full w-full block md:hidden"
      />
    </div>
  );
}
