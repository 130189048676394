import React, { useEffect, useState } from "react";
import RestaurantsLocalization from "../RestaurantsLocalization/RestaurantsLocalization";
import Newsletter from "../Newsletter/Newsletter";
import SpecialEvents from "../SpecialEvents/SpecialEvents";
import OwnProducts from "../OwnProducts/OwnProducts";
import Restaurants from "../Restaurants/Restaurants";
import AboutUs from "../AboutUs/AboutUs";
import Hero from "../Hero/Hero";
import FloatingLogoAndButton from "../FloatingLogoAndButton/FloatingLogoAndButton";
import Loading from "../Loading/Loading";
import ReservationMenu from "../ReservationMenu/ReservationMenu";
import EventInfo from "../EventInfo/EventInfo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LandingPage() {
  const [timeLoading, setTimeLoading] = useState(true);
  const [resMenu, setResMenu] = useState(false);
  const [eventMenu, setEventMenu] = useState(false);
  useEffect(() => {
    setTimeout(setTimeLoading(false), 5000);
  }, []);
  return (
    <div className="bg-black min-h-screen text-white">
      {timeLoading ? (
        <Loading />
      ) : (
        <>
          <FloatingLogoAndButton resMenuOpener={setResMenu} />
          <Hero />
          <AboutUs />
          <Restaurants />
          <OwnProducts />
          <SpecialEvents myFunc={setEventMenu} />
          <Newsletter />
          <RestaurantsLocalization />
          {resMenu ? <ReservationMenu resCloseFunc={setResMenu} /> : <></>}
          {eventMenu ? <EventInfo showEvent={setEventMenu} /> : <></>}
          <ToastContainer />
        </>
      )}
    </div>
  );
}
