import React from "react";
import ButtonRed from "../ButtonRed/ButtonRed";
import meat1Image from "../../assets/images/webp/meat1-webp.webp";
import meat2Image from "../../assets/images/webp/meat2-webp.webp";
import meat3Image from "../../assets/images/webp/meat3-webp.webp";

export default function AboutUs() {
  return (
    <div className="h-full md:h-screen flex flex-col-reverse md:flex-row justify-center items-center max-w-[1280px] mx-auto overflow-hidden">
      <div className="w-full">
        <div className="text-center">
          <h3 className="text-6xl uppercase">WhiteBull</h3>
          <hr className="my-4" />
          <p className="text-xl uppercase">Steakhouse & burbone bar</p>
        </div>
        <div className="px-4 py-6 uppercase text-sm">
          Przechodząc przez nasze drzwi, zostajesz przywitany ciepłą atmosferą i
          zapachem grillowanego mięsa uniesionym w powietrzu. Nasza pasja do
          doskonałego jedzenia i bogatego smaku burbonu jest w każdym detalu -
          od rustykalnego wystroju po wyrafinowane dania i szeroki wybór
          trunków.
          <br />
          <br />
          Nasi kucharze mistrzowsko przygotowują świeże kawałki najwyższej
          jakości mięsa, abyś mógł cieszyć się soczystymi stekami o doskonałej
          konsystencji i intensywnym smaku. Każdy kęs jest jak kulinarne
          arcydzieło, w którym połączenie świeżości, jakości i umiejętności
          tworzy niezapomniane doznania.
          <br />
          <br />
          W naszym barze znajdziesz bogatą kolekcję najwykwintniejszych whisky i
          bourbonów z różnych regionów i destylarni. Od łagodnych i subtelnych,
          po intensywne i dymne - nasz wybór napojów pozwoli Ci odkryć szeroki
          wachlarz smaków tego wyjątkowego trunku.
          <br />
          <br />
          Nasz personel z pasją i wiedzą zawsze jest gotowy doradzić Ci
          najlepsze połączenia mięsa z whisky, zapewniając niezapomniane
          doznania dla Twojego podniebienia.
          <br />
          <br />
          Czy chcesz doświadczyć połączenia doskonałego mięsa i wybornego
          bourbonu? Przyjdź do naszego steakhouse'u i burbonowego baru, gdzie
          tradycja smakuje jak nigdzie indziej.
        </div>
        <div className="text-center my-4 animate-bounce">
          <ButtonRed
            text={"Zarezerwuj stolik"}
            type={"outputLink"}
            url={"https://zjedz.my/whitebull-steakhouse"}
          />
        </div>
      </div>
      <div className="w-screen md:w-full flex items-center justify-center">
        <div className="flex gap-10 items-center rotate-12">
          <div className="h-64 w-1/4 md:w-28 rounded-full">
            <img
              src={meat1Image}
              alt="meat"
              className="object-center w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="h-96 w-1/4 md:w-28 rounded-full">
            <img
              src={meat2Image}
              alt="meat"
              className="object-center w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="h-64 w-1/4 md:w-28 rounded-full">
            <img
              src={meat3Image}
              alt="meat"
              className="object-center w-full h-full object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
