import React from "react";
import WhitebullVerticalBar from "../WhitebullVerticalBar/WhitebullVerticalBar";
import adress from "../../constants";
import RestaurantCard from "../RestaurantCard/RestaurantCard";
import wbLogo from "../../assets/images/whitebull.png";

export default function Restaurants() {
  return (
    <div className="h-full md:h-screen flex flex-col md:flex-row justify-center items-center">
      <div className="h-full md:h-2/3 flex flex-col md:flex-row justify-center items-center max-w-[1280px]">
        <RestaurantCard restaurant={adress[0]} />
        <WhitebullVerticalBar />
        <div className="w-full md:w-1/3 h-1/3 md:h-full flex items-center justify-center">
          <img
            src={wbLogo}
            alt="whitebull-logo"
            className="w-full h-60 object-cover"
          />
        </div>
        <WhitebullVerticalBar />
        <RestaurantCard restaurant={adress[1]} />
      </div>
    </div>
  );
}
