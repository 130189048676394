import React, { useState } from "react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import CarouselItem from "../CarouselItem/CarouselItem";

export default function Carousel({ itemList }) {
  const [current, setCurrent] = useState(0);

  const previusSlide = () => {
    if (current === 0) setCurrent(itemList.length - 1);
    else setCurrent(current - 1);
  };
  const nextSlide = () => {
    if (current === itemList.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };
  return (
    <div className="overflow-hidden relative">
      <div
        className={`flex transition ease-out duration-300 justify-center py-8`}
        style={{ transform: `translateX(-${current * 100})` }}
      >
        {itemList.map((i) => {
          return (
            <CarouselItem item={i} key={`${i.productName}-carousel-item`} />
          );
        })}
      </div>

      <div className="absolute top-0 h-full w-full flex items-center justify-between px-4 text-3xl">
        <button onClick={previusSlide} className="group">
          <FaRegArrowAltCircleLeft className="group-hover:text-red-600" />
        </button>
        <button onClick={nextSlide} className="group">
          <FaRegArrowAltCircleRight className="group-hover:text-red-600" />
        </button>
      </div>

      <div className="absolute bottom-12 py-2 flex justify-center gap-4 w-full">
        {itemList.map((i, n) => {
          return (
            <div
              onClick={() => {
                setCurrent(n);
              }}
              key={`circle-${n}`}
              className={`rounded-full w-5 h-5 cursor-pointer ${
                n === current ? "bg-red-600" : "bg-gray-300"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
}
