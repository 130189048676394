import React from "react";
import Carousel from "../Carousel/Carousel";
import vodka from "../../assets/images/webp/vodkaWB-webp.webp";

export default function OwnProducts() {
  const productsList = [
    {
      imgPath: vodka,
      productName: "Ognista woda",
      productDescription: `Wódka ziemniaczana to trunek o bogatej historii, który zawdzięcza swój
      charakter i smak starannemu procesowi produkcyjnemu oraz wyjątkowej
      kombinacji tradycji i rzemiosła. Jest to płynna esencja, która
      przenosi nas w głąb ziemi, gdzie ukryta jest moc natury i tradycji.
 
      
      Gdy wlewamy ją do kieliszka, już na pierwszy rzut oka widać jej
      kryształową klarowność, świadectwo starannego procesu destylacji.
      Aromat, który unosi się delikatnie nad kieliszkiem, jest subtelny, ale
      zauważalny - nuty ziemniaków, delikatna słodycz i odrobina ziemi,
      która przywołuje wspomnienia polnych pól i urodzajnych gleb.
      
      
      Wódka ziemniaczana to nie tylko trunek do picia, to również symbol
      tradycji, historii i kultury. To płyn, który łączy pokolenia i
      miejsca, przenosząc nas w podróż przez czas i przestrzeń, od dawnych
      wsi i dworków, po nowoczesne bary i restauracje. To także zaproszenie
      do odkrycia piękna prostoty i elegancji w każdym łyku.`,
    },
    // {
    //   imgPath:
    //     "https://images.unsplash.com/photo-1449157291145-7efd050a4d0e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //   productName: "Ognista woda 2",
    //   productDescription: `Wódka ziemniaczana to trunek o bogatej historii, który zawdzięcza swój
    //   charakter i smak starannemu procesowi produkcyjnemu oraz wyjątkowej
    //   kombinacji tradycji i rzemiosła. Jest to płynna esencja, która
    //   przenosi nas w głąb ziemi, gdzie ukryta jest moc natury i tradycji.

    //   Gdy wlewamy ją do kieliszka, już na pierwszy rzut oka widać jej
    //   kryształową klarowność, świadectwo starannego procesu destylacji.
    //   Aromat, który unosi się delikatnie nad kieliszkiem, jest subtelny, ale
    //   zauważalny - nuty ziemniaków, delikatna słodycz i odrobina ziemi,
    //   która przywołuje wspomnienia polnych pól i urodzajnych gleb.

    //   Wódka ziemniaczana to nie tylko trunek do picia, to również symbol
    //   tradycji, historii i kultury. To płyn, który łączy pokolenia i
    //   miejsca, przenosząc nas w podróż przez czas i przestrzeń, od dawnych
    //   wsi i dworków, po nowoczesne bary i restauracje. To także zaproszenie
    //   do odkrycia piękna prostoty i elegancji w każdym łyku.`,
    // },
    // {
    //   imgPath:
    //     "https://images.unsplash.com/photo-1496568816309-51d7c20e3b21?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //   productName: "Ognista woda 3",
    //   productDescription: `Wódka ziemniaczana to trunek o bogatej historii, który zawdzięcza swój
    //   charakter i smak starannemu procesowi produkcyjnemu oraz wyjątkowej
    //   kombinacji tradycji i rzemiosła. Jest to płynna esencja, która
    //   przenosi nas w głąb ziemi, gdzie ukryta jest moc natury i tradycji.

    //   Gdy wlewamy ją do kieliszka, już na pierwszy rzut oka widać jej
    //   kryształową klarowność, świadectwo starannego procesu destylacji.
    //   Aromat, który unosi się delikatnie nad kieliszkiem, jest subtelny, ale
    //   zauważalny - nuty ziemniaków, delikatna słodycz i odrobina ziemi,
    //   która przywołuje wspomnienia polnych pól i urodzajnych gleb.

    //   Wódka ziemniaczana to nie tylko trunek do picia, to również symbol
    //   tradycji, historii i kultury. To płyn, który łączy pokolenia i
    //   miejsca, przenosząc nas w podróż przez czas i przestrzeń, od dawnych
    //   wsi i dworków, po nowoczesne bary i restauracje. To także zaproszenie
    //   do odkrycia piękna prostoty i elegancji w każdym łyku.`,
    // },
  ];
  return (
    <div className="hidden md:block max-h-screen">
      <div className="w-[80%] m-auto">
        <Carousel itemList={productsList} />
      </div>
    </div>
  );
}
