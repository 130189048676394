import React from "react";
import wbLogo from "../../assets/images/whitebull.png";

export default function Loading() {
  return (
    <div className="w-full h-screen bg-black flex justify-center items-center">
      <img
        src={wbLogo}
        alt="Whitebull"
        className="h-1/5 w-1/5 object-contain animate-pulse"
      />
    </div>
  );
}
