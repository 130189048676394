import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import LandingPage from "./components/LandingPage/LandingPage";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import restaurantsItems from "./constants";
import React, { Suspense } from "react";
import Loading from "./components/Loading/Loading";
import AdminLayout from "./components/AdminLayout/AdminLayout";
import AdminNewsletter from "./components/AdminNewsletter/AdminNewsletter";
import AdminWorks from "./components/AdminWorks/AdminWorks";
import AdminApplications from "./components/AdminApplications/AdminApplications";
import AdminDocuments from "./components/AdminDocuments/AdminDocuments";

function App() {
  const LazyRestaurant = React.lazy(() => {
    return import("./components/RestaurantPage/RestaurantPage");
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route path="/" element={<Footer />}>
            <Route path="/" element={<Main />}>
              <Route path="/" element={<LandingPage />} />

              <Route
                path={restaurantsItems[0].appLink}
                element={
                  <Suspense fallback={<Loading />}>
                    <LazyRestaurant myItem={restaurantsItems[0]} />
                  </Suspense>
                }
              />

              <Route
                path={restaurantsItems[1].appLink}
                element={
                  <Suspense fallback={<Loading />}>
                    <LazyRestaurant myItem={restaurantsItems[1]} />
                  </Suspense>
                }
              />
              <Route path="/admin-panel/" element={<AdminLogin />}>
                {/* <Route path="/admin-panel/" element={<AdminLayout />}>
                <Route
                  path="/admin-panel/dashboard/"
                  element={<AdminDashboard />}
                />
                <Route
                  path="/admin-panel/newsletter/"
                  element={<AdminNewsletter />}
                />
                <Route path="/admin-panel/works/" element={<AdminWorks />} />
                <Route
                  path="/admin-panel/applications/"
                  element={<AdminApplications />}
                />
                <Route
                  path="/admin-panel/documents/"
                  element={<AdminDocuments />}
                /> */}
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
