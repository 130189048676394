import React, { useEffect, useState } from "react";
import RestaurantsLocalizationItem from "../RestaurantsLocalizationItem/RestaurantsLocalizationItem";
import adress from "../../constants";

export default function RestaurantsLocalization() {
  const [locations, setLocations] = useState([]);

  const setData = () => {
    const myList = [];
    adress.forEach((location) =>
      myList.push(
        <RestaurantsLocalizationItem
          key={`res-${location.id}`}
          item={location}
        />
      )
    );
    setLocations(myList);
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div className="border-t-2 border-white">
      <div className={"text-center"}>
        <h2 className="text-3xl pt-10">Restauracje</h2>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-8">
        {locations}
      </div>
    </div>
  );
}
