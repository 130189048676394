import React from "react";
import ButtonRed from "../ButtonRed/ButtonRed";

export default function ReservationMenu({ resCloseFunc }) {
  const clickHandler = () => {
    resCloseFunc(false);
  };
  return (
    <div className="fixed top-0 left-0 w-screen md:w-full h-full bg-black/95 z-40 flex flex-col justify-center items-center gap-4">
      <h3 className="text-lg md:text-2xl py-4">
        Zarezerwuj stolik w jednym z naszych lokali:
      </h3>
      <ButtonRed
        text={"Toruń"}
        type={"outputLink"}
        url={"https://zjedz.my/whitebull-steakhouse"}
      />
      <ButtonRed
        text={"Bydgoszcz"}
        type={"outputLink"}
        url={"https://zjedz.my/whitebull-steakhouse"}
      />
      <button
        className="fixed top-2 right-2 md:right-2 hover:text-red-600"
        onClick={clickHandler}
      >
        [ <span className="text-red-600 text-lg">x</span> ] Zamknij
      </button>
    </div>
  );
}
