import React from "react";

export default function RestaurantsLocalizationItem({ item }) {
  return (
    <div className="text-center p-10">
      <div>
        <h3 className="text-2xl">{item.city}</h3>
      </div>
      <div>
        <p className="text-xl">{item.street}</p>
      </div>
      <div className="text-lg">
        <p>Godziny otwarcia:</p>
        <p>PON - CZW</p>
        <p>{item.openHours1}</p>
      </div>
      <div className="text-lg">
        <p>PT - SOB</p>
        <p>{item.openHours2}</p>
      </div>
      <div className="text-lg">
        <p>Niedziela</p>
        <p>{item.openHours3}</p>
      </div>
      <div className="text-lg">
        <p>tel.{item.tel}</p>
      </div>
    </div>
  );
}
