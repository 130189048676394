import React from "react";

export default function EventInfo({ showEvent }) {
  const clickHandler = () => {
    showEvent(false);
  };
  return (
    <div className="bg-black/90 fixed top-0 left-0 h-full w-full flex flex-col justify-center items-center z-40">
      <button
        onClick={clickHandler}
        className="fixed top-2 right-2 hover:text-red-600"
      >
        [ <span className="text-red-600">x</span> ] Zamknij
      </button>
      <div className="bg-black p-10">
        <div className="p-10 border-[1px] border-white flex flex-wrap items-center justify-center gap-2">
          <p>Rezerwacja sali: </p>
          <a href="tel:+48505635671" className="text-red-600">
            +48 505-635-671
          </a>
        </div>
      </div>
    </div>
  );
}
